import axios from '@axios'

export default {
  namespaced: true,
  state: {
    speakers: {},

  },
  getters: {},
  mutations: {

    GET_speakers(state, payload) {
      state.speakers = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('speakers-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addspeakers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('speakers', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    Allspeakers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('speakers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    speakersList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('speakers-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updatespeakers(ctx, payload) {
      // 
      // 
      return new Promise((resolve, reject) => {
        axios
          .post(`speakers/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Deletespeakers(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`speakers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getspeakers(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`speakers/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getCoursesByspeakers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-by-speakers', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getspeakersByArticle(ctx, {id,type}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`posts-by-speakers/${id}/${type}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

   
  },

}
