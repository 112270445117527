import axios from '@axios'

export default {
  namespaced: true,
  state: {
    category: {},

    partner:{}
  },
  getters: {},
  mutations: {

    GET_CATEGORY(state, payload) {
      state.category = payload
    },
    GET_Partner(state, payload) {
      state.partner = payload.data
    },
  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('categories-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('categories', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },  
    Updatesponsors(ctx, payload) {
      // 
      // 
      return new Promise((resolve, reject) => {
        axios
          .put(`sponsors/${payload.id}`, payload.result)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addsponsors(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('sponsors', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    allsponsors(ctx, queryParams) {
     
      return new Promise((resolve, reject) => {
        axios
          .get('sponsors', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getsponsors(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`sponsors/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    Deletesponsors(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`sponsors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('categories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    CategoryList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('category-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCategory(ctx, payload) {
      // 
      // 
      return new Promise((resolve, reject) => {
        axios
          .post(`categories/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCategory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`categories/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getCoursesByCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-by-category', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getCategoryByArticle(ctx, {id,type}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`posts-by-category/${id}/${type}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

   
  },

}
