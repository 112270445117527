import axios from '@axios'

export default {
  namespaced: true,
  state: {
    courses: {},

  },
  getters: {},
  mutations: {

    GET_COURSE(state, payload) {
  
      state.courses = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('long-course', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkLink(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('validate-link', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-from-classical', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteGenerateCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`code-generator/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`long-course/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`long-course/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSelectedCourses(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('long-course-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`long-course/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    GetCourseRecorded(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`long-course/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllCoursesOnline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('get-online-long-courses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesOnlinePrice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('online-long-courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesClassicPrice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('classic-long-courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('get-classical-long-courses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('long-course-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    getCoursesByCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`get-all-data-long-courses-by-category/${queryParams.category_id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCoursesByCategoryClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`classic-long-courses-list`,{ params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCoursesByCategoryOnline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`online-long-courses-list`,{ params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getGenerateCourse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('generate-online-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('long-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get('related-long-course-dropdown')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
