import axiosSupport from '@/libs/axiosSipport'

export default {
  namespaced: true,
  state: {
    ticket: {},
    replies:[]
  },
  getters: {},
  mutations: {
    GET_tickets(state, payload) {
      state.ticket = payload
      console.log(state.ticket)
    },
    GET_tickets_Replay(state, payload) {
      state.replies = payload
      console.log(state.ticket)
    },
  },
  actions: {
    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .post('events-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTickets(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .post('client/tickets',payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    AssignTickets(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .post('client/tickets/assign',payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    Alltickets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .get('client/tickets', { params: queryParams })
          .then(response => {
            console.log("pp",response.data.pagination)
            resolve(response.data.pagination)})
          .catch(error => reject(error))
      })
    },
    AllUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .get('/api/v1/search/all?perPage=20',)
          .then(response => {
            console.log("UserList",response.data)
            resolve(response.data)})
          .catch(error => reject(error))
      })
    },
    AllCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .get('client/ticket-categories', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    eventList(ctx) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .get('event-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updateevent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .post(`client/tickets/${payload.id}`, payload.formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Delettickets(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .delete(`client/tickets/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    replyTicket(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .post(`client/tickets/${payload.ticket_id}/replies`,payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Gettickets(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosSupport
          .get(`client/tickets/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
  },
}
