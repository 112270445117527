import axios from '@axios'

export default {
  namespaced: true,
  state: {
    event: {},

  },
  getters: {},
  mutations: {

    GET_event(state, payload) {
      state.event = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('events-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addevent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('events', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    Allevent(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('events', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllNews(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('news', { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    eventList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('event-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updateevent(ctx, payload) {
      // 
      // 
      return new Promise((resolve, reject) => {
        axios
          .post(`events/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Deleteevent(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getevent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`events/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getCoursesByevent(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-by-event', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    geteventByArticle(ctx, {id,type}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`posts-by-event/${id}/${type}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

   
  },

}
