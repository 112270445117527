import axios from '@axios'

export default {
  namespaced: true,
  state: {
    organisers: {},

  },
  getters: {},
  mutations: {

    GET_organisers(state, payload) {
      state.organisers = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('organisers-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addorganisers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('orgnisers', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    Allorganisers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('orgnisers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    organisersList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('orgnisers-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updateorganisers(ctx, payload) {
      // 
      // 
      return new Promise((resolve, reject) => {
        axios
          .post(`orgnisers/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Deleteorganisers(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`orgnisers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getorganisers(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`orgnisers/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

  
   
  },

}
