import axios from '@axios'

export default {
  namespaced: true,
  state: {
    city :{},
    CategoryContent:{}
   
  },
  getters: {},
  mutations: {
    
    GET_CITY(state,payload) {
     
      state.city = payload
     
    },
    CategoryContent(state,payload) {
      state.CategoryContent = payload
     
     
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourseContent(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post('course-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addCourseRecorded(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post('recorded-courses-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addLongCourseContent(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post('long-course-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addCategoryContent(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post('category-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addCategoryinCity(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post('category-city-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetCategoryInCity(ctx, payload) {
      
        return new Promise((resolve, reject) => {
          axios
            .get(`category-city-content/${payload.city}/${payload.category}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addCityContent(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post('city-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
     
      DeleteCity(ctx, id) {
        
      
        return new Promise((resolve, reject) => {
          axios
            .delete(`cities/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetCategoryContent(ctx ) {
        return new Promise((resolve, reject) => {
          axios
            .get('settings?filter[name]=categories_page_content_classic')
            .then(response => {
          
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      GetCategoryContentOnline(ctx ) {
        return new Promise((resolve, reject) => {
          axios
            .get('settings?filter[name]=categories_page_content_online')
            .then(response => {
          
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      UpdateCategoryContentClassic(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post(`settings/${payload.classicId}`,  payload.formDataClassic ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      UpdateCategoryContentOnline(ctx, payload) {
        
       
        return new Promise((resolve, reject) => {
          axios
            .post(`settings/${payload.onlineId}`,  payload.formDataOnline ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getAllcitites(ctx, ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('cities')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

  
},

}
