export default [
  {
    
    path: '/homepage',
    name: 'index-homepage',
    component: () => import('@/views/homepage/index.vue'),
    children: [


    
    
    {
      path: '/add-upcoming-course',
      name: 'add-upcoming-course',
      component: () => import('@/views/homepage/AddUpComingCourse.vue'),
      meta:{
        resource: 'upcoming-course',
        action:'view'
      }
    },
    {
      path: '/upcoming-course',
      name: 'upcoming-course',
      component: () => import('@/views/homepage/UpcomingCourse.vue'),
      meta:{
        resource: 'upcoming-course',
        action:'view'
      }
    },
    {
      path: '/change-feature-course',
      name: 'change-feature-course',
      component: () => import('@/views/homepage/ChangeFeatureCourse.vue'),
      meta:{
            resource: 'upcoming-course',
        action:'view'
      }
    },
    {
        path: '/feature-course',
        name: 'feature-course',
        component: () => import('@/views/homepage/FeatureCourse.vue'),
        meta:{
              resource: 'upcoming-course',
          action:'view'
        }
      }
  ]
},
{
    
  path: '/homepage',
  name: 'index-homepage',
  component: () => import('@/views/homepage-long-course/index.vue'),
  children: [


  
  
  {
    path: '/add-upcoming-Diplomas',
    name: 'add-upcoming-Diplomas',
    component: () => import('@/views/homepage-long-course/AddUpComingCourse.vue'),
    meta:{
      resource: 'upcoming-Diplomas',
      action:'view'
    }
  },
  {
    path: '/upcoming-Diplomas',
    name: 'upcoming-Diplomas',
    component: () => import('@/views/homepage-long-course/UpcomingCourse.vue'),
    meta:{
      resource: 'upcoming-Diplomas',
      action:'view'
    }
  },
  {
    path: '/change-feature-Diplomas',
    name: 'change-feature-Diplomas',
    component: () => import('@/views/homepage-long-course/ChangeFeatureCourse.vue'),
    meta:{
          resource: 'upcoming-Diplomas',
      action:'view'
    }
  },
  {
      path: '/feature-Diplomas',
      name: 'feature-Diplomas',
      component: () => import('@/views/homepage-long-course/FeatureCourse.vue'),
      meta:{
            resource: 'upcoming-Diplomas',
        action:'view'
      }
    }
]
},

]