import axios from '@axios'

export default {
  namespaced: true,
  state: {
    courses: {},

  },
  getters: {},
  mutations: {

    GET_COURSE(state, payload) {
      state.courses = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('training-course', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDeticatedContent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('training-course-content', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    SeotrainingCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('seo-training-course', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('training-schedules', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetSeoCourseContent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-training-course/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`training-course/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`training-courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSeoCotnent(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`seo-training-course/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeletetrainingSchedule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`training-schedules/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`training-course/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('training-course-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Alltrainingcourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('training-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AlltrainingcoursesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('training-course-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAlltrainingschedules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('training-schedules', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesSeoDeticated(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-training-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },

}
