export default [
   
    {
      path: '/Schedule/',
      name: 'schedule-index',
      component: () => import('@/views/Schedule/index.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    children:[
      {
        path: '/schedule-classical-course',
        name: 'schedule-classical-course',
        component: () => import('@/views/Schedule/ScheduleClassicCourse.vue'),
        meta:{
          resource: 'schedule',
          action:'view'
        },
      },
    {
      path: '/schedule-update/:id',
      name: 'schedule-update',
      component: () => import('@/views/Schedule/UpdateSchedule.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    },
    {
      path: '/schedule-online-course',
      name: 'schedule-online-course',
      component: () => import('@/views/Schedule/ScheduleOnlineCourse.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    },
    {
      path: '/all-Schedule',
      name: 'all-Schedule',
      component: () => import('@/views/Schedule/ViewSchedule.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    },
  ]},
  {
    path: '/Diplomas-Schedule/',
    name: 'Diplomas-Schedule-index',
    component: () => import('@/views/LongCourseSchedule/index.vue'),
    meta:{
      resource: 'schedule_Diplomas',
      action:'view'
    },
  children:[
    {
      path: '/schedule-classical-Diplomas',
      name: 'schedule-classical-Diplomas',
      component: () => import('@/views/LongCourseSchedule/ScheduleClassicCourse.vue'),
      meta:{
        resource: 'schedule_Diplomas',
        action:'view'
      },
    },
  {
    path: '/Diplomas-schedule-update/:id',
    name: 'Diplomas-schedule-update',
    component: () => import('@/views/LongCourseSchedule/UpdateSchedule.vue'),
    meta:{
      resource: 'schedule_Diplomas',
      action:'view'
    },
  },
  {
    path: '/schedule-online-Diplomas',
    name: 'schedule-online-Diplomas',
    component: () => import('@/views/LongCourseSchedule/ScheduleOnlineCourse.vue'),
    meta:{
      resource: 'schedule_Diplomas',
      action:'view'
    },
  },
  {
    path: '/all-Diplomas-Schedule',
    name: 'all-Diplomas-Schedule',
    component: () => import('@/views/LongCourseSchedule/ViewSchedule.vue'),
    meta:{
      resource: 'schedule_Diplomas',
      action:'view'
    },
  },
]}
    
  ]
  