import axios from 'axios'

const token = localStorage.getItem('SupportToken')
const tokenType = 'Bearer'
const axiosSupport = axios.create({
  baseURL: 'https://support.prokoders.work', // base URL of the support API
  headers: { Authorization: `${tokenType} ${token}`, Accept: 'application/json' },
})


export default axiosSupport
