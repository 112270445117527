export default [  
  
  {
    path: '/long-course-content',
    name: 'long-course-content',
    component: () => import('@/views/content/LongCourseContent.vue'),
    meta:{
       resource: 'long_course_content',
      action:'view'
    }
  },
  {
  path: '/content',
      name: 'index-content',
      component: () => import('@/views/content/index.vue'),
  children: [

    {
      path: '/category-content',
      name: 'category-content',
      component: () => import('@/views/content/CategoryContent.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
   
   
    {
      path: '/category-in-city-content',
      name: 'category-in-city-content',
      component: () => import('@/views/content/CategoryinCityContent.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
    {
      path: '/category-page-content',
      name: 'category-page-content',
      component: () => import('@/views/content/CategoryPageContent.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
    {
      path: '/city-content',
      name: 'city-content',
      component: () => import('@/views/content/CityContent.vue'),
      meta:{
        resource: 'course_content',
        action:'view'
      }
    },
    {
        path: '/course-content',
        name: 'course-content',
        component: () => import('@/views/content/CourseContent.vue'),
        meta:{
          resource: 'course_content',
          action:'view'
        }
      }
  ]
}
,
  {
  path: '/recorded',
      name: 'index-recorded',
      component: () => import('@/views/recorded/index.vue'),
  children: [

    {
      path: '/recorded-course',
      name: 'recorded-course',
      component: () => import('@/views/recorded/RecordedCourse.vue'),
      meta:{
         resource: 'recorded_course',
        action:'view'
      }
    },
   
   
    {
      path: '/recorded-list',
      name: 'recorded-list',
      component: () => import('@/views/recorded/List.vue'),
      meta:{
         resource: 'recorded_course',
        action:'view'
      }
    },
   
    
  ]
}

]