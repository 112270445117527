export default [
  {
    path: '/courses',
    // redirect: "/courses",
    name: 'index-course',
    component: () => import('@/views/courses/index.vue'),
    children: [
      {
        path: '/add-course',
        name: 'add-course',
        component: () => import('@/views/courses/AddCourse.vue'),
        meta: {
          resource: 'course',
          action: 'view',
        },
      },
      // {
      //   path: '/courses',
      //   name: 'index-course',
      //   component: () => import('@/views/courses/index.vue'),
      //   // meta:{
      //   //   resource: 'all',
      //   //   action:'view'
      //   // }
      // },
      {
        path: '/update-course/:id',
        name: 'update-courses',
        component: () => import('@/views/courses/UpdateCourses.vue'),
        meta: {
          resource: 'course',
          action: 'view',
        },
      },
      {
        path: '/update-course-partnership/:id',
        name: 'update-course-partnership',
        component: () => import('@/views/courses/partnership/UpdatePartner.vue'),
        meta: {
          resource: 'course',
          action: 'update',
        },
      },
      {
        path: '/classical-courses',
        name: 'classical-courses',
        component: () => import('@/views/courses/ClassicalCourses.vue'),
        meta: {
          resource: 'course',
          action: 'view',
        },
      },
      {
        path: '/generate-course',
        name: 'generate-course',
        component: () => import('@/views/courses/generateOnlineCourse.vue'),
        meta: {
          resource: 'course',
          action: 'view',
        },
      },
      {
        path: '/online-courses',
        name: 'online-courses',
        component: () => import('@/views/courses/OnlineCourses.vue'),
        meta: {
          resource: 'course',
          action: 'view',
        },
      },
      {
        path: '/course-partnership',
        // redirect: "/courses",
        name: 'index-course-partnership',
        component: () => import('@/views/courses/partnership/index.vue'),
        meta: {
          resource: 'course',
          action: 'view',
        },
        children: [
          {
            path: '/add-course-partnership',
            name: 'add-course-partnership',
            component: () => import('@/views/courses/partnership/AddPartnership.vue'),
            meta: {
              resource: 'course',
              action: 'view',
            },
          },
         
    
          {
            path: '/all-course-partnership',
            name: 'all-course-partnership',
            component: () => import('@/views/courses/partnership/list.vue'),
            meta: {
              resource: 'course',
              action: 'view',
            },
          },
          
    
        ],
      },
    ],
  },
  {
    path: '/long-courses',
    // redirect: "/courses",
    name: 'index-long-courses',
    component: () => import('@/views/longcourse/index.vue'),
    children: [
      {
        path: '/add-long-courses',
        name: 'add-long-courses',
        component: () => import('@/views/longcourse/AddCourse.vue'),
        meta: {
          resource: 'long-courses',
          action: 'view',
        },
      },
      // {
      //   path: '/courses',
      //   name: 'index-course',
      //   component: () => import('@/views/courses/index.vue'),
      //   // meta:{
      //   //   resource: 'all',
      //   //   action:'view'
      //   // }
      // },
      {
        path: '/update-long-courses/:id',
        name: 'update-long-courses',
        component: () => import('@/views/longcourse/UpdateCourses.vue'),
        meta: {
          resource: 'long-courses',
          action: 'view',
        },
      },
      {
        path: '/classical-long-courses',
        name: 'classical-long-courses',
        component: () => import('@/views/longcourse/ClassicalCourses.vue'),
        meta: {
          resource: 'long-courses',
          action: 'view',
        },
      },
      {
        path: '/generate-long-courses',
        name: 'generate-long-courses',
        component: () => import('@/views/longcourse/generateOnlineCourse.vue'),
        meta: {
          resource: 'long-courses',
          action: 'view',
        },
      },
      {
        path: '/online-long-courses',
        name: 'online-long-courses',
        component: () => import('@/views/longcourse/OnlineCourses.vue'),
        meta: {
          resource: 'long-courses',
          action: 'view',
        },
      },
    ],
  },
  

  {
    path: '/schema',
    name: 'index-schema',
    component: () => import('@/views/schema/index.vue'),
    // meta:{
    //   resource: 'all',
    //   action:'view'
    // }
    children: [
      {

        path: '/add-schema-course',
        name: 'add-schema-course',
        component: () => import('@/views/schema/SchemaCourse.vue'),
        children: [
          
          {

            path: '/add-schema-classic',
            name: 'add-schema-classic',
            component: () => import('@/views/schema/ClassicalSchema.vue'),
            meta: {
              resource: 'schema_manager',
              action: 'view',
            },
          },
          {

            path: '/add-schema-online',
            name: 'add-schema-online',
            component: () => import('@/views/schema/OnlineSchema.vue'),
            meta: {
              resource: 'schema_manager',
              action: 'view',
            },
          },
        ],
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {

        path: '/add-schema-post',
        name: 'add-schema-post',
        component: () => import('@/views/schema/PostSchema.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {

        path: '/add-schema-event',
        name: 'add-schema-event',
        component: () => import('@/views/schema/SchemaEvent.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {
        path: '/faq-schema-index',
        name: 'faq-schema-index',
        component: () => import('@/views/schema/FaqSchema.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {
        path: '/faq-schema',
        name: 'faq-schema',
        component: () => import('@/views/schema/AddSchemaFaq.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {
        path: '/faq-schema-list',
        name: 'faq-schema-list',
        component: () => import('@/views/schema/SchemaFaq.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {
        path: '/statistics-schema',
        name: 'statistics-schema',
        component: () => import('@/views/schema/SchemaStatistics.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {
        path: '/online-schema',
        name: 'online-schema',
        component: () => import('@/views/schema/OnlineCourse.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
      {
        path: '/classic-schema',
        name: 'classic-schema',
        component: () => import('@/views/schema/ClassicalCourse.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },
     

      {
        path: '/all-schema',
        name: 'all-schema',
        component: () => import('@/views/schema/allSchema.vue'),
        meta: {
          resource: 'schema_manager',
          action: 'view',
        },
      },

    ],
  },

]
