export default [
  {
    path: '/categories',
    // redirect: "/courses",
    name: 'index-category',
    component: () => import('@/views/categories/index.vue'),
    meta: {
      resource: 'category',
      action: 'view',
    },
    children: [
      {
        path: '/add-category',
        name: 'add-category',
        component: () => import('@/views/categories/AddCategory.vue'),
        meta: {
          resource: 'category',
          action: 'view',
        },
      },
      {
        path: '/update-category/:id',
        name: 'update-category',
        component: () => import('@/views/categories/UpdateCategory.vue'),
        meta: {
          resource: 'category',
          action: 'update',
        },
      },
      {
        path: '/update-partnership/:id',
        name: 'update-partnership',
        component: () => import('@/views/categories/partnership/UpdatePartner.vue'),
        meta: {
          resource: 'category',
          action: 'update',
        },
      },

      {
        path: '/all-categories',
        name: 'all-categories',
        component: () => import('@/views/categories/categoriesList.vue'),
        meta: {
          resource: 'category',
          action: 'view',
        },
      },
      {
        path: '/partnership',
        // redirect: "/courses",
        name: 'index-partnership',
        component: () => import('@/views/categories/partnership/index.vue'),
        meta: {
          resource: 'category',
          action: 'view',
        },
        children: [
          {
            path: '/add-partnership',
            name: 'add-partnership',
            component: () => import('@/views/categories/partnership/AddPartnership.vue'),
            meta: {
              resource: 'category',
              action: 'view',
            },
          },
         
    
          {
            path: '/all-partnership',
            name: 'all-partnership',
            component: () => import('@/views/categories/partnership/list.vue'),
            meta: {
              resource: 'category',
              action: 'view',
            },
          },
    
        ],
      },

    ],
  },
  {
    path: '/events',
    // redirect: "/courses",
    name: 'index-events',
    component: () => import('@/views/events/index.vue'),
    meta: {
      resource: 'events',
      action: 'view',
    },
    children: [
      {
        path: '/add-events',
        name: 'add-events',
        component: () => import('@/views/events/AddEvent.vue'),
        meta: {
          resource: 'events',
          action: 'view',
        },
      },
      {
        path: '/update-events/:id',
        name: 'update-events',
        component: () => import('@/views/events/UpdateEvent.vue'),
        meta: {
          resource: 'events',
          action: 'update',
        },
      },

      {
        path: '/all-events',
        name: 'all-event',
        component: () => import('@/views/events/eventsList.vue'),
        meta: {
          resource: 'events',
          action: 'view',
        },
      },

    ],
  },
  {
    path: '/speakers',
    // redirect: "/courses",
    name: 'index-speakers',
    component: () => import('@/views/speaker/index.vue'),
    meta: {
      resource: 'speakers',
      action: 'view',
    },
    children: [
      {
        path: '/add-speakers',
        name: 'add-speakers',
        component: () => import('@/views/speaker/AddSpeaker.vue'),
        meta: {
          resource: 'speakers',
          action: 'view',
        },
      },
      {
        path: '/update-speakers/:id',
        name: 'update-speakers',
        component: () => import('@/views/speaker/UpdateSpeaker.vue'),
        meta: {
          resource: 'speakers',
          action: 'update',
        },
      },

      {
        path: '/all-speakers',
        name: 'all-speakers',
        component: () => import('@/views/speaker/speakerList.vue'),
        meta: {
          resource: 'speakers',
          action: 'view',
        },
      },

    ],
  },
  {
    path: '/organiser',
    // redirect: "/courses",
    name: 'index-organiser',
    component: () => import('@/views/organiser/index.vue'),
    meta: {
      resource: 'organiser',
      action: 'view',
    },
    children: [
      {
        path: '/add-organiser',
        name: 'add-organiser',
        component: () => import('@/views/organiser/AddOrganiser.vue'),
        meta: {
          resource: 'organiser',
          action: 'view',
        },
      },
      {
        path: '/update-organiser/:id',
        name: 'update-organiser',
        component: () => import('@/views/organiser/UpdateOrganiser.vue'),
        meta: {
          resource: 'organiser',
          action: 'update',
        },
      },

      {
        path: '/all-organiser',
        name: 'all-organiser',
        component: () => import('@/views/organiser/organiserList.vue'),
        meta: {
          resource: 'organiser',
          action: 'view',
        },
      },

    ],
  },
  {
    path: '/contact-us',
    // redirect: "/courses",
    name: 'contact-us',
    component: () => import('@/views/contact-us/index.vue'),
    meta: {
      resource: 'contact-us',
      action: 'view',
    },
    children: [
      {
        path: '/add-contact-us',
        name: 'add-contact-us',
        component: () => import('@/views/contact-us/AddContactUs.vue'),
        meta: {
          resource: 'contact-us',
          action: 'view',
        },
      },
      {
        path: '/update-contact-us/:id',
        name: 'update-contact-us',
        component: () => import('@/views/contact-us/UpdateContactUs.vue'),
        meta: {
          resource: 'contact-us',
          action: 'update',
        },
      },

      {
        path: '/all-contact-us',
        name: 'all-contact-us',
        component: () => import('@/views/contact-us/List.vue'),
        meta: {
          resource: 'contact-us',
          action: 'view',
        },
      },

    ],
  },
  {
    path: '/ticket',
  
    name: 'index-ticket',
    component: () => import('@/views/ticket/index.vue'),
    meta: {
      resource: 'ticket',
      action: 'view',
    },
    children: [
      {
        path: '/add-ticket',
        name: 'add-ticket',
        component: () => import('@/views/ticket/AddTicket.vue'),
        meta: {
          resource: 'ticket',
          action: 'view',
        },
      },
      {
        path: '/show-ticket/:id',
        name: 'show-ticket',
        component: () => import('@/views/ticket/UpdateTicket.vue'),
        meta: {
          resource: 'ticket',
          action: 'update',
        },
      },

      {
        path: '/all-ticket',
        name: 'all-ticket',
        component: () => import('@/views/ticket/TicketList.vue'),
        meta: {
          resource: 'ticket',
          action: 'view',
        },
      },

    ],
  },


]
