export default [
  {
    path: '/web-pages',
    name: 'web-pages',
    
    component: () => import('@/views/webPage.vue'),
    children: [
      {
        path: '/footer',
        name: 'footer',
        component: () => import('@/views/Footer.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/BecomeInstructor',
        name: 'BecomeInstructor',
        component: () => import('@/views/BecomeInstructor.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/AccerditiosPage',
        name: 'AccerditiosPage',
        component: () => import('@/views/AccerditiosPage.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/sitemap',
        name: 'sitemap',
        component: () => import('@/views/SiteMap.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },

      {
        path: '/dynamic-sitemap',
        name: 'dynamic-sitemap',
        component: () => import('@/views/GenerateDynamicMap.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/dynamic-pages-list',
        name: 'dynamic-pages-list',
        component: () => import('@/views/dynamicPages.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/dynamic-pages/:id',
        name: 'dynamic-pages',
        component: () => import('@/views/updateDynaimcPage.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/privacy.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },

      {
        path: '/AboutUs',
        name: 'about_us',
        component: () => import('@/views/AboutUs.vue'),
        meta: {
          resource: 'web_pages',
          action: 'show',
        },
      },
      {
        path: '/ConsultingServices',
        name: 'ConsultingServices',
        component: () => import('@/views/ConsultingServices.vue'),
        meta: {
          resource: 'web_pages',
          action: 'view',
        },
      },
      {
        path: '/Terms',
        name: 'terms',
        component: () => import('@/views/Terms.vue'),
        meta: {
          resource: 'web_pages',
              action: 'view',
        },
      },
      {
        path: '/quality-policy',
        name: 'quality-policy',
        component: () => import('@/views/Quality.vue'),
        meta: {
          resource: 'web_pages',
              action: 'view',
        },
      },
    ],
  },

]
