import axios from '@axios'

export default {
  namespaced: true,
  state: {
    contactus: {},

  },
  getters: {},
  mutations: {

    GET_CONTACT_US(state, payload) {
      state.contactus = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contact-us-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContactUs(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contact-us', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    AllcontactUs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('contact-us', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    CategoryList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('category-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCategory(ctx, payload) {
      //
      //
      return new Promise((resolve, reject) => {
        axios
          .post(`contact-us/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeletecontactUs(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`contact-us/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetContactUs(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contact-us/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },


  

  },

}
