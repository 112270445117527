import axios from '@axios'

export default {
  namespaced: true,
  state: {
    courses: {},
    partner:{}
  },
  getters: {},
  mutations: {

    GET_COURSE(state, payload) {
      state.courses = payload
    },
    GET_Partner(state, payload) {
      state.partner = payload.data
    },
  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('courses', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkLink(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('validate-link', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-from-classical', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteGenerateCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`code-generator/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`courses/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCourseRecorded(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`recorded-courses-content/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSelectedCourses(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('courses-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    SkillShare(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('recordedcourse') // Full URL provided for this request
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`courses/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllCoursesOnline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-online', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesRecordedList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('recorded-courses-content', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesRecorded(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('recorded-courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-classic', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSchemaCourse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/course-schemas/${queryParams.course}/${queryParams.city}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSchemaCourseOnline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/online-course-schemas/${queryParams.course}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesDiplomaList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('long-courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getGenerateCourse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('generate-online-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get('related-courses-dropdown')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

   
  Updatesponsors(ctx, payload) {
    // 
    // 
    return new Promise((resolve, reject) => {
      axios
        .put(`course_sponsers/${payload.id}`, payload.result)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addsponsors(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('course_sponsers', payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  allsponsors(ctx, queryParams) {
   
    return new Promise((resolve, reject) => {
      axios
        .get('course_sponsers', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  Getsponsors(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`course_sponsers/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  Deletesponsors(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`course_sponsers/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},
}
