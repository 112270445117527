import axios from '@axios'

export default {
  namespaced: true,
  state: {
    certificate: {},
    blog: {},

  },
  getters: {},
  mutations: {

    GET_CERTIFICATE(state, payload) {
      state.certificate = payload
    },
    GET_HIDDEN_COURSE(state, payload) {
      state.blog = payload
    },
  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addhiddencourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('black-list', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    UpdateHiddenCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`black-list/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
 
    allHiddenCourse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('black-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    DeleteHiddenCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`black-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   


    GethideenCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`black-list/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },



  },

}
