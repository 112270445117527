export default [
  {
    path: '/client',
    name: 'index-client',
    component: () => import('@/views/clients/index.vue'),

    children: [
      {
        path: '/add-client',
        name: 'add-client',
        component: () => import('@/views/clients/AddClient.vue'),
        meta: {
          resource: 'client',
          action: 'view',
        },
      },

      {
        path: '/update-client/:id',
        name: 'update-client',
        component: () => import('@/views/clients/UpdateClient.vue'),
        meta: {
          resource: 'client',
          action: 'view',
        },
      },
      {
        path: '/all-client',
        name: 'all-client',
        component: () => import('@/views/clients/AllClients.vue'),
        meta: {
          resource: 'client',
          action: 'view',
        },
      }],
  },
  {
    path: '/mail/',
    name: 'index-mails',
    component: () => import('@/views/mail/index.vue'),
    meta: {
      resource: 'mail',
      action: 'view',
    },
  },
  {
    path: '/accreditations/',
    name: 'index-accreditation',
    component: () => import('@/views/accreditations/index.vue'),
    meta: {
      resource: 'accreditation',
      action: 'view',
    },
    children: [
      {
        path: '/add-accreditation',
        name: 'add-accreditation',
        component: () => import('@/views/accreditations/AddAccreditation.vue'),
        meta: {
          resource: 'accreditation',
          action: 'view',
        },
      },

      {
        path: '/update-accreditations/:id',
        name: 'update-accreditation',
        component: () => import('@/views/accreditations/UpdateAccreditation.vue'),
        meta: {
          resource: 'accreditation',
          action: 'view',
        },
      },

      {
        path: '/all-accreditations',
        name: 'all-accreditations',
        component: () => import('@/views/accreditations/AllAccreditation.vue'),
        meta: {
          resource: 'accreditation',
          action: 'view',
        },
      },
    ],
  },
  {
    path: '/mail/all-mails',
    name: 'all-mails',
    component: () => import('@/views/mail/AllMails.vue'),
    meta: {
      resource: 'mail',
      action: 'view',
    },
  },

  {
    path: '/country-pages/',
    name: 'index-country-pages',
    component: () => import('@/views/countrypages/index.vue'),
    meta: {
      resource: 'country_pages',
      action: 'view',
    },
    children: [
      {
        path: '/add-specific-price-country-page',
        name: 'add-specific-price-country-page',
        component: () => import('@/views/countrypages/AddSpecificPrice.vue'),

        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
      {
        path: '/faq/',
        name: 'index-faq',
        component: () => import('@/views/countrypages/faq/index.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },

        children: [
          {
            path: '/add-faq/:id',
            name: 'add-faq',
            component: () => import('@/views/countrypages/faq/Addfaq.vue'),
            meta: {
              resource: 'country_pages',
              action: 'view',
            },
          },

          {
            path: '/update-faq/:id/:countryPage',
            name: 'update-faq',
            component: () => import('@/views/countrypages/faq/Updatefaq.vue'),
            meta: {
              resource: 'country_pages',
              action: 'view',
            },
          },

          {
            path: '/all-country-faq/:id',
            name: 'all-country-faq',
            component: () => import('@/views/countrypages/faq/Allfaq.vue'),
            meta: {
              resource: 'country_pages',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/full-discount/country-page',
        name: 'index-full-discount-country-page',
        component: () => import('@/views/countrypages/full-discount/index.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },

        children: [
          {
            path: '/add-full-discount-country-page/:id',
            name: 'add-full-discount-country-page',
            component: () => import('@/views/countrypages/full-discount/addFullDiscount.vue'),
            meta: {
              resource: 'country_pages',
              action: 'view',
            },
          },

          {
            path: '/update-full-discount-country-page/:ngoId/:id',
            name: 'update-full-discount-country-page',
            component: () => import('@/views/countrypages/full-discount/updateFullDiscount.vue'),
            meta: {
              resource: 'country_pages',
              action: 'view',
            },
          },

          {
            path: '/all-full-discount-country-page/:id',
            name: 'all-full-discount-country-page',
            component: () => import('@/views/countrypages/full-discount/allFullDiscount.vue'),
            meta: {
              resource: 'country_pages',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/add-country-pages',
        name: 'add-country-pages',
        component: () => import('@/views/countrypages/Addcountrypages.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },

      {
        path: '/update-country-pages/:id',
        name: 'update-country-pages',
        component: () => import('@/views/countrypages/Updatecountrypages.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
      {
        path: '/add-schedule-country-pages/:id',
        name: 'add-schedule-country-pages',
        component: () => import('@/views/countrypages/AddSchedule.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
      {
        path: '/list-schedule-country-pages/:id',
        name: 'list-schedule-country-pages',
        component: () => import('@/views/countrypages/listScheduleCourse.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
      {
        path: '/add-long-schedule-country-pages/:id',
        name: 'add-long-schedule-country-pages',
        component: () => import('@/views/countrypages/addScheduleLong.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
      {
        path: '/list-long-schedule-country-pages/:id',
        name: 'list-long-schedule-country-pages',
        component: () => import('@/views/countrypages/ListScheduleOtherCourse.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
      {
        path: '/all-country-pages',
        name: 'all-country-pages',
        component: () => import('@/views/countrypages/Allcountrypages.vue'),
        meta: {
          resource: 'country_pages',
          action: 'view',
        },
      },
    ],
  },
  {
    path: '/ngos/',
    name: 'index-ngos',
    component: () => import('@/views/ngos/index.vue'),
    meta: {
      resource: 'ngos',
      action: 'view',
    },
    children: [
      {
        path: '/add-specific-price-ngos',
        name: 'add-specific-price-ngos',
        component: () => import('@/views/ngos/AddSpecificPrice.vue'),

        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/faq/ngos',
        name: 'index-faq-ngos',
        component: () => import('@/views/ngos/faq/index.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },

        children: [
          {
            path: '/add-faq-ngos/:id',
            name: 'add-faq-ngos',
            component: () => import('@/views/ngos/faq/Addfaq.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/update-faq-ngos/:id/:ngos',
            name: 'update-faq-ngos',
            component: () => import('@/views/ngos/faq/Updatefaq.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/all-ngos-faq/:id',
            name: 'all-ngos-faq',
            component: () => import('@/views/ngos/faq/Allfaq.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/full-discount/ngos',
        name: 'index-full-discount-ngos',
        component: () => import('@/views/ngos/full-discount/index.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },

        children: [
          {
            path: '/add-full-discount-ngos/:id',
            name: 'add-full-discount-ngos',
            component: () => import('@/views/ngos/full-discount/addFullDiscount.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/update-full-discount-ngos/:ngoId/:id',
            name: 'update-full-discount-ngos',
            component: () => import('@/views/ngos/full-discount/updateFullDiscount.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/all-full-discount-ngos/:id',
            name: 'all-full-discount-ngos',
            component: () => import('@/views/ngos/full-discount/allFullDiscount.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/project/ngos',
        name: 'index-project-ngos',
        component: () => import('@/views/ngos/project/index.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },

        children: [
          {
            path: '/add-project-ngos/:id',
            name: 'add-project-ngos',
            component: () => import('@/views/ngos/project/addproject.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/update-project-ngos/:id/:ngoId',
            name: 'update-project-ngos',
            component: () => import('@/views/ngos/project/updateproject.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/all-ngos-project/:id',
            name: 'all-ngos-project',
            component: () => import('@/views/ngos/project/allprojects.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/logo/ngos',
        name: 'index-logo-ngos',
        component: () => import('@/views/ngos/logo/index.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },

        children: [
          {
            path: '/add-logo-ngos/:id',
            name: 'add-logo-ngos',
            component: () => import('@/views/ngos/logo/addlogo.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/update-logo-ngos/:id/:ngoId',
            name: 'update-logo-ngos',
            component: () => import('@/views/ngos/logo/updatelogo.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/all-ngos-logo/:id',
            name: 'all-ngos-logo',
            component: () => import('@/views/ngos/logo/alllogo.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/price/ngos',
        name: 'index-price-ngos',
        component: () => import('@/views/ngos/price/index.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },

        children: [
          {
            path: '/add-price-ngos/:id',
            name: 'add-price-ngos',
            component: () => import('@/views/ngos/price/addprice.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/update-price-ngos/:id/:ngoId',
            name: 'update-price-ngos',
            component: () => import('@/views/ngos/price/updateprice.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/all-ngos-price/:id',
            name: 'all-ngos-price',
            component: () => import('@/views/ngos/price/allprices.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/long-price/ngos',
        name: 'index-long-price-ngos',
        component: () => import('@/views/ngos/long-price/index.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },

        children: [
          {
            path: '/add-long-price-ngos/:id',
            name: 'add-long-price-ngos',
            component: () => import('@/views/ngos/long-price/addprice.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/update-long-price-ngos/:id/:ngoId',
            name: 'update-long-price-ngos',
            component: () => import('@/views/ngos/long-price/updateprice.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },

          {
            path: '/all-ngos-long-price/:id',
            name: 'all-ngos-long-price',
            component: () => import('@/views/ngos/long-price/allprices.vue'),
            meta: {
              resource: 'ngos',
              action: 'view',
            },
          },
        ],
      },
      {
        path: '/add-ngos',
        name: 'add-ngos',
        component: () => import('@/views/ngos/Addngos.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },

      {
        path: '/update-ngos/:id',
        name: 'update-ngos',
        component: () => import('@/views/ngos/Updatengos.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/add-schedule-ngos/:id',
        name: 'add-schedule-ngos',
        component: () => import('@/views/ngos/AddSchedule.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/list-schedule-ngos/:id',
        name: 'list-schedule-ngos',
        component: () => import('@/views/ngos/listScheduleCourse.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/add-other-schedule-ngos/:id',
        name: 'add-other-schedule-ngos',
        component: () => import('@/views/ngos/AddScheduleOtherCourse.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/add-long-schedule-ngos/:id',
        name: 'add-long-schedule-ngos',
        component: () => import('@/views/ngos/addScheduleLong.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/list-long-schedule-ngos/:id',
        name: 'list-long-schedule-ngos',
        component: () => import('@/views/ngos/ListScheduleOtherCourse.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
      {
        path: '/all-ngos',
        name: 'all-ngos',
        component: () => import('@/views/ngos/AllNgos.vue'),
        meta: {
          resource: 'ngos',
          action: 'view',
        },
      },
    ],
  },
]
